import {Id} from 'normalized-reducer'

import {IngredientsContextType, IngredientState, IIngredientRenderer, DragObjectType, includes, PropertyValueType} from '../libs/types'

abstract class IngredientRenderer implements IIngredientRenderer {
  classes: string[] = []
  includes: includes = {}

  protected ingredientsContext: IngredientsContextType
  protected ingredientId: Id
  protected ingredientType: DragObjectType

  constructor(
    ingredientsContext: IngredientsContextType,
    ingredientType: DragObjectType,
    ingredientId: Id
  ) {
    this.ingredientsContext = ingredientsContext
    this.ingredientType = ingredientType
    this.ingredientId = ingredientId
  }

  abstract render(): JSX.Element

  abstract generateCode(): string

  protected getIngredient(type: DragObjectType, id: Id) {
    return this.ingredientsContext.ingredientsSelectors.getEntity<IngredientState>(
      this.ingredientsContext.ingredientsState, {
        type: type,
        id: id
      }
    )
  }

  protected generateValueCode(
    value: string | number | boolean | undefined,
    isLiteral: boolean,
    includeQuotes: boolean = true
  ) {
    if (isLiteral && typeof value === 'string') {
      if (includeQuotes === true) {
        return `"${value}"`
      } else {
        return value
      }
    } else {
      return `{${value}}`
    }
  }

  protected generatePropCode(
    name: string,
    value: string | number | boolean | undefined,
    type: PropertyValueType | undefined,
    defaultValue?: string | number,
    defaultType?: PropertyValueType,
    omitDefault: boolean = false
  ) {
    return (omitDefault === false || (value !== defaultValue || type !== defaultType)) ?
      `${name}=${this.generateValueCode(value, type === 'literal')}` :
      ''
  }
}

export default IngredientRenderer
