import clsx from 'clsx'

import CanvasProvider from '../../contexts/CanvasProvider'
import useNavTabs from '../../hooks/useNavTabs'
import {CanvasProps, Mode} from '../../libs/types'

import Ingredient from './Ingredient'

function Canvas({componentId}: CanvasProps) {
  const [mode, modeNavTabs] = useNavTabs<Mode>([{
    name: 'design',
    displayName: 'Design'
  }, {
    name: 'preview',
    displayName: 'Preview'
  }, {
    name: 'code',
    displayName: 'Code'
  }],
  'design'
  )

  return (
    <div className="align-items-stretch d-flex flex-fill overflow-auto">
      <div className="card text-center flex-fill">
        <div className="card-header d-flex justify-content-between">
          {modeNavTabs}

          {/* TODO */}
          {/* <button className="btn btn-sm" title="Open in CodeSandbox">
            <i className="bi bi-box-arrow-up-right" />
          </button> */}
        </div>

        <div
          className={clsx(
            'card-body d-flex overflow-auto flex-column gap-3',
            mode === 'code' && 'p-0'
          )}
          data-testid={`CanvasCardBody-${componentId}`}
        >
          <CanvasProvider componentId={componentId} mode={mode}>
            <Ingredient type="component" ingredientId={componentId} index={0} />
          </CanvasProvider>
        </div>
      </div>
    </div>
  )
}

export default Canvas
