import clsx from 'clsx'
import {MouseEvent, useState} from 'react'

import {Tab} from '../libs/types'

function useNavTabs<T>(
  tabs: Tab<T>[],
  defaultActiveTab: T): [
  T,
  JSX.Element
] {
  const [activeTab, setActiveTab] = useState<T>(defaultActiveTab)

  const handleNavItemClick = (tabName: T) => {
    setActiveTab(tabName)
  }

  const navTabs = (
    <ul className="nav nav-tabs card-header-tabs">
      {tabs.map((tab, index) => (
        <li className="nav-item" key={index}>
          <button
            className={clsx('nav-link', activeTab === tab.name && 'active')}
            aria-current="true"
            data-mode="design"
            data-testid={`nav-link-${tab.name}`}
            onClick={(_: MouseEvent) => handleNavItemClick(tab.name)}
          >
            {tab.displayName}
          </button>
        </li>
      ))}
    </ul>
  )

  return [activeTab, navTabs]
}

export default useNavTabs
