import IngredientRenderer from '../../../renderers/IngredientRenderer'
import {IIngredientRenderer} from '../../types'
import Button from '../components/Button'

class ButtonRenderer extends IngredientRenderer implements IIngredientRenderer {
  render() {
    return <Button ingredientId={this.ingredientId} />
  }

  generateCode() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    return `<button type="button"${ingredient?.properties.enabled.value.value === 'false' ? 'disabled="false"' : ''}>
  ${this.generateValueCode(
    ingredient?.properties.text.value.value,
    ingredient?.properties.text.value.type === 'literal',
    false
  )}
</button>`
  }
}

export default ButtonRenderer
