import _ from 'lodash'
import {Id} from 'normalized-reducer'

import Component from '../components/canvas/Component'
import IngredientRendererFactory from '../libs/bootstrap/renderers/IngredientRendererFactory'
import {IIngredientRenderer, includes} from '../libs/types'
import ContainerRenderer from '../renderers/ContainerRenderer'

class ComponentRenderer extends ContainerRenderer implements IIngredientRenderer {
  indentation = 2

  render() {
    return <Component componentId={this.ingredientId} />
  }

  generateCode() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    const includes = this.generateIncludes()
    const hooks = this.generateHooks()

    return `${includes !== '' ? `${includes}\n\n` : ''}function ${ingredient?.properties.name.value.value}() {
${hooks !== '' ? `${hooks}\n\n` : ''}  return (
${ingredient?.childrenIds?.length === 0 ? `${this.indent()}<></>` : this.generateChildren()}
  )
}

export default ${ingredient?.properties.name.value.value}
`
  }

  private generateIncludes() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    var includes: includes = {}

    if (ingredient?.hookIds === undefined) {
      return ''
    }

    ingredient.hookIds.forEach((hookId: Id) => {
      const child = this.getIngredient('hook', hookId)

      includes = _.merge(includes, new IngredientRendererFactory().getRenderer(
        this.ingredientsContext,
        child?.type!,
        hookId
      ).includes)
    })

    return Object.entries(includes).map(([path, include]) => {
      const defaultInclude = include.default !== undefined ? include.default : ''
      const namedIncludes = include.named !== undefined ? `{${include.named.sort().join(', ')}}` : ''
      const separator = include.default !== undefined && include.named !== undefined ? ', ' : ''

      return `import ${defaultInclude}${separator}${namedIncludes} from '${path}'`
    }).join('\n')
  }

  private generateHooks() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    if (ingredient?.hookIds === undefined) {
      return ''
    }

    return ingredient.hookIds.map((hookId: Id) => {
      const child = this.getIngredient('hook', hookId)

      return new IngredientRendererFactory().getRenderer(
        this.ingredientsContext,
        child?.type!,
        hookId
      ).generateCode().split('\n').map((line: string) => `  ${line}`).join('\n')
    }).join('\n\n')
  }
}

export default ComponentRenderer
