import ContainerRenderer from '../../../../renderers/ContainerRenderer'
import {IIngredientRenderer} from '../../../types'
import VBox from '../../components/containers/VBox'

class VBoxRenderer extends ContainerRenderer implements IIngredientRenderer {
  render() {
    return <VBox ingredientId={this.ingredientId} />
  }

  generateCode() {
    return `<div className="d-flex flex-column gap-3" style={{width: '100%'}}>
${this.generateChildren()}
</div>`
  }
}

export default VBoxRenderer
