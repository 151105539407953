import {SimulatedStateActionType} from '../libs/actionTypes'
import {simulatedState, SimulatedStateAction} from '../libs/types'

function simulatedStateReducer(state: simulatedState, action: SimulatedStateAction): simulatedState {
  switch (action.type) {
    case SimulatedStateActionType.removeState: {
      const payload = action.payload

      const newState = {...state}
      delete newState[payload.key]

      return newState
    }
    case SimulatedStateActionType.setState: {
      const payload = action.payload

      const newState = {...state,
        [payload.key]: payload.value
      }

      return newState
    }
  }
}

export default simulatedStateReducer
