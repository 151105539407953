import IngredientRenderer from '../../../renderers/IngredientRenderer'
import {IIngredientRenderer, includes} from '../../types'

class UseStateRenderer extends IngredientRenderer implements IIngredientRenderer {
  includes: includes = {
    'react': {
      named: ['useState']
    }
  }

  render() {
    return <></>
  }

  generateCode() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    const name = ingredient?.properties.name.value.value as string
    const setterName = `set${name.charAt(0).toUpperCase() + name.slice(1)}`
    const type = ingredient?.properties.type.value.value as string
    const defaultValue = ingredient?.properties.defaultValue.value.value as string
    const defaultValueString = type === 'string' ? `'${defaultValue}'` : defaultValue

    return `const [${name}, ${setterName}] = useState(${defaultValueString})`
  }
}

export default UseStateRenderer
