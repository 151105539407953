import {createContext} from 'react'

import {ComponentProviderProps, DragObjectType} from '../libs/types'

export const ComponentContext = createContext<{
  shouldShow: (type: DragObjectType) => boolean
} | null>(null)

function ComponentProvider({hooksShow, componentsShow, children}: ComponentProviderProps) {
  const shouldShow = (type: DragObjectType) => {
    if (type === 'hook') {
      return hooksShow
    } else {
      return componentsShow
    }
  }

  return (
    <ComponentContext.Provider value={{shouldShow}}>
      {children}
    </ComponentContext.Provider>
  )
}

export default ComponentProvider
