import IngredientRenderer from '../../../renderers/IngredientRenderer'
import {IIngredientRenderer} from '../../types'
import Spacer from '../components/Spacer'

class SpacerRenderer extends IngredientRenderer implements IIngredientRenderer {
  classes = ['flex-fill']

  render() {
    return <Spacer />
  }

  generateCode() {
    return '<div className="flex-fill" />'
  }
}

export default SpacerRenderer
