import _ from 'lodash'
import {Id} from 'normalized-reducer'
import {Fragment, useContext} from 'react'
import {ConnectDropTarget, useDrop} from 'react-dnd'

import Dropzone from '../components/canvas/Dropzone'
import Ingredient from '../components/canvas/Ingredient'
import {CanvasContext} from '../contexts/CanvasProvider'
import {ComponentContext} from '../contexts/ComponentProvider'
import {IngredientsContext} from '../contexts/IngredientsProvider'
import {SimulatedStateContext} from '../contexts/SimulatedStateProvider'
import {SimulatedStateActionType} from '../libs/actionTypes'
import ingredientInitialStates from '../libs/ingredientInitialStates'
import {DragObjectType, IngredientDragType} from '../libs/types'

import useIngredient from './useIngredient'

function useContainer(
  type: DragObjectType,
  ingredientId: Id
): [JSX.Element, ConnectDropTarget] {
  const ingredientsContext = useContext(IngredientsContext)!
  const simulatedStateContext = useContext(SimulatedStateContext)!
  const canvasContext = useContext(CanvasContext)!
  const componentContext = useContext(ComponentContext)!

  const [ingredient] = useIngredient('component', ingredientId)

  // eslint-disable-next-line no-empty-pattern
  const [{}, dropRef] = useDrop({
    accept: type
  })

  const handleDrop = (ingredientDragObject: IngredientDragType, destinationIndex: number) => {
    const actions = []

    const newIngredientId = _.uniqueId(`${ingredientDragObject.ingredientType}_`)

    // Create. No ID means it's new.
    if (ingredientDragObject.id === undefined) {
      actions.push(
        ingredientsContext.ingredientsActionCreators.create(
          type,
          newIngredientId,
          ingredientInitialStates[ingredientDragObject.ingredientType],
          destinationIndex
        )
      )

      if (ingredientDragObject.ingredientType === 'use-state') {
        simulatedStateContext.simulatedStateDispatch({
          type: SimulatedStateActionType.setState,
          payload: {
            key: ingredientInitialStates[ingredientDragObject.ingredientType].properties.name.value.value as string,
            value: ingredientInitialStates[ingredientDragObject.ingredientType].properties.value.value.value as string
          }
        })
      }
    // Move. ID means it already exists.
    // From attached.
    } else if (ingredientDragObject.parentId !== undefined) {
      actions.push(
        ingredientsContext.ingredientsActionCreators.detach(
          type,
          ingredientDragObject.parentId,
          type === 'hook' ? 'hookIds' : 'childrenIds',
          ingredientDragObject.id
        )
      )
    }

    actions.push(
      ingredientsContext.ingredientsActionCreators.attach(
        'component',
        ingredientId,
        type === 'hook' ? 'hookIds' : 'childrenIds',
        ingredientDragObject.id === undefined ? newIngredientId : ingredientDragObject.id,
        {index: destinationIndex}
      )
    )

    ingredientsContext.ingredientsDispatch(
      ingredientsContext.ingredientsActionCreators.batch(...actions)
    )
  }

  const renderChild = (childId: Id, index: number) => {
    const dropzoneIndex = index + 1

    return (
      <Fragment key={index}>
        <Ingredient type={type} ingredientId={childId} index={index} />

        {canvasContext.mode === 'design' && (
          <Dropzone
            ingredientId={ingredientId}
            index={dropzoneIndex}
            acceptableType={type}
            show={componentContext.shouldShow(type)}
            handleDrop={handleDrop}
          />
        )}
      </Fragment>
    )
  }

  const renderedChildren = (
    <>
      {canvasContext.mode === 'design' && (
        <Dropzone
          ingredientId={ingredientId}
          index={0}
          acceptableType={type}
          show={componentContext.shouldShow(type)}
          handleDrop={handleDrop}
        />
      )}

      {type === 'component' ?
        ingredient.childrenIds?.map(renderChild) :
        ingredient.hookIds?.map(renderChild)
      }
    </>
  )

  return [renderedChildren, dropRef]
}

export default useContainer
