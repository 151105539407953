import {useContext} from 'react'
import AceEditor from 'react-ace'
import {DropTargetMonitor, useDrop} from 'react-dnd'
import 'ace-builds/src-noconflict/mode-jsx'

import {CanvasContext} from '../../contexts/CanvasProvider'
import ComponentProvider from '../../contexts/ComponentProvider'
import {IngredientsContext} from '../../contexts/IngredientsProvider'
import useIngredient from '../../hooks/useIngredient'
import IngredientRendererFactory from '../../libs/bootstrap/renderers/IngredientRendererFactory'
import {ComponentProps} from '../../libs/types'

import IngredientsContainer from './IngredientsContainer'

function Component({componentId}: ComponentProps) {
  const ingredientsContext = useContext(IngredientsContext)!
  const canvasContext = useContext(CanvasContext)!

  const [ingredient] = useIngredient('component', componentId)

  // TODO
  // const previewState = useState()

  const [{
    hooksIsOver,
    hooksCanDrop,
    hooksDraggedIndex
  }, hooksDropRef] = useDrop({
    accept: 'hook',
    collect: (monitor: DropTargetMonitor) => ({
      hooksIsOver: monitor.isOver(),
      hooksCanDrop: monitor.canDrop(),
      hooksDraggedIndex: monitor.getItem()?.index
    })
  })

  // Dragged IngredientButton.
  const hooksShow = (hooksDraggedIndex === undefined && (hooksIsOver || hooksCanDrop)) ||
    // Dragged hook.
    (hooksDraggedIndex !== undefined && hooksIsOver)

  const [{
    componentsIsOver,
    componentsCanDrop,
    componentsDraggedIndex
  }, componentsDropRef] = useDrop({
    accept: 'component',
    collect: (monitor: DropTargetMonitor) => ({
      componentsIsOver: monitor.isOver(),
      componentsCanDrop: monitor.canDrop(),
      componentsDraggedIndex: monitor.getItem()?.index
    })
  })

  // Dragged IngredientButton.
  const componentsShow = (componentsDraggedIndex === undefined && (componentsIsOver || componentsCanDrop)) ||
    // Dragged component.
    (componentsDraggedIndex !== undefined && componentsIsOver)

  return (
    <ComponentProvider
      hooksShow={hooksShow}
      componentsShow={componentsShow}
    >
      {canvasContext.mode === 'design' && (
        <IngredientsContainer
          type="hook"
          componentId={componentId}
          title="Hooks"
          direction="row"
          style={{height: '127px', maxHeight: '127px'}}
          dropRef={hooksDropRef}
        />
      )}

      {canvasContext.mode === 'code' ? (
        <AceEditor
          mode="jsx"
          style={{width: '100%', height: '100%'}}
          editorProps={{$blockScrolling: true}}
          readOnly={true}
          showPrintMargin={false}
          value={
            new IngredientRendererFactory().getRenderer(
              ingredientsContext,
              ingredient.type,
              componentId
            ).generateCode()
          }
        />
      ) : (
        <IngredientsContainer
          type="component"
          componentId={componentId}
          title="Components"
          direction="column"
          dropRef={componentsDropRef}
        />
      )}
    </ComponentProvider>
  )
}

export default Component
