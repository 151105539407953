import {IngredientState} from './types'

const ingredientInitialStates: { [key: string]: IngredientState } = {
  'use-state': {
    displayName: 'useState',
    type: 'use-state',
    properties: {
      name: {
        canBeAnExpression: false,
        displayName: 'Name',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'state'
        },
        value: {
          type: 'literal',
          value: 'state'
        }
      },
      defaultValue: {
        canBeAnExpression: true,
        displayName: 'Default Value',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'value'
        },
        value: {
          type: 'literal',
          value: 'value'
        }
      },
      type: {
        canBeAnExpression: false,
        displayName: 'Type',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'string'
        },
        value: {
          type: 'literal',
          value: 'string'
        },
        options: [
          {
            displayName: 'Boolean',
            value: 'boolean'
          },
          {
            displayName: 'Number',
            value: 'number'
          },
          {
            displayName: 'String',
            value: 'string'
          }
        ]
      },
      value: {
        canBeAnExpression: true,
        displayName: 'Value',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'value'
        },
        value: {
          type: 'literal',
          value: 'value'
        }
      }
    },
    parentId: undefined,
    childrenIds: []
  },
  component: {
    displayName: 'Component',
    type: 'component',
    properties: {
      name: {
        canBeAnExpression: false,
        displayName: 'Name',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'CustomComponent'
        },
        value: {
          type: 'literal',
          value: 'CustomComponent'
        }
      }
    },
    parentId: undefined,
    childrenIds: []
  },
  button: {
    displayName: 'Button',
    type: 'button',
    properties: {
      enabled: {
        canBeAnExpression: true,
        displayName: 'Enabled',
        type: 'boolean',
        defaultValue: {
          type: 'literal',
          value: 'true'
        },
        value: {
          type: 'literal',
          value: 'true'
        }
      },
      size: {
        canBeAnExpression: true,
        displayName: 'Size',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'md'
        },
        value: {
          type: 'literal',
          value: 'md'
        },
        options: [
          {
            displayName: 'Small',
            value: 'sm'
          }, {
            displayName: 'Medium',
            value: 'md'
          }, {
            displayName: 'Large',
            value: 'lg'
          }
        ]
      },
      style: {
        canBeAnExpression: true,
        displayName: 'Style',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'primary'
        },
        value: {
          type: 'literal',
          value: 'primary'
        },
        options: [
          {
            displayName: 'Primary',
            value: 'primary'
          }, {
            displayName: 'Secondary',
            value: 'secondary'
          }, {
            displayName: 'Success',
            value: 'success'
          }, {
            displayName: 'Danger',
            value: 'danger'
          }, {
            displayName: 'Warning',
            value: 'warning'
          }, {
            displayName: 'Info',
            value: 'info'
          }, {
            displayName: 'Light',
            value: 'light'
          }, {
            displayName: 'Dark',
            value: 'dark'
          }, {
            displayName: 'Link',
            value: 'link'
          }
        ]
      },
      text: {
        canBeAnExpression: true,
        displayName: 'Text',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'Button'
        },
        value: {
          type: 'literal',
          value: 'Button'
        }
      }
    },
    parentId: undefined
  },
  grid: {
    displayName: 'Grid',
    type: 'grid',
    properties: {},
    parentId: undefined,
    childrenIds: []
  },
  hbox: {
    displayName: 'HBox',
    type: 'hbox',
    properties: {},
    parentId: undefined,
    childrenIds: []
  },
  image: {
    displayName: 'Image',
    type: 'image',
    properties: {
      source: {
        canBeAnExpression: true,
        displayName: 'Source',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'
        },
        value: {
          type: 'literal',
          value: 'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'
        }
      }
    },
    parentId: undefined
  },
  spacer: {
    displayName: 'Spacer',
    type: 'spacer',
    properties: {},
    parentId: undefined,
    childrenIds: []
  },
  text: {
    displayName: 'Text',
    type: 'text',
    properties: {
      enabled: {
        canBeAnExpression: true,
        displayName: 'Enabled',
        type: 'boolean',
        defaultValue: {
          type: 'literal',
          value: 'true'
        },
        value: {
          type: 'literal',
          value: 'true'
        }
      },
      text: {
        canBeAnExpression: true,
        displayName: 'Text',
        type: 'string',
        defaultValue: {
          type: 'literal',
          value: 'Text'
        },
        value: {
          type: 'literal',
          value: 'Text'
        }
      }
    },
    parentId: undefined
  },
  vbox: {
    displayName: 'VBox',
    type: 'vbox',
    properties: {},
    parentId: undefined,
    childrenIds: []
  }
}

export default ingredientInitialStates
