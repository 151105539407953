import {Id} from 'normalized-reducer'
import {createContext} from 'react'

import {CanvasProviderProps, Mode} from '../libs/types'

export const CanvasContext = createContext<{
  componentId: Id
  mode: Mode
} | null>(null)

function CanvasProvider({componentId, mode, children}: CanvasProviderProps) {
  return (
    <CanvasContext.Provider value={{componentId, mode}}>
      {children}
    </CanvasContext.Provider>
  )
}

export default CanvasProvider
