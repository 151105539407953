import Grid from '../../../../libs/bootstrap/components/containers/Grid'
import ContainerRenderer from '../../../../renderers/ContainerRenderer'
import {IIngredientRenderer} from '../../../types'

class GridRenderer extends ContainerRenderer implements IIngredientRenderer {
  render() {
    return <Grid ingredientId={this.ingredientId} />
  }

  generateCode() {
    return `<div className="d-flex flex-wrap gap-3" style={{width: '100%'}}>
${this.generateChildren()}
</div>`
  }
}

export default GridRenderer
