import {useDrag} from 'react-dnd'

import {IngredientButtonProps} from '../../../../libs/types'

function IngredientButton({name, iconName, type, ingredientType}: IngredientButtonProps) {
  const [{opacity}, dragRef] = useDrag({
    item: {
      type: type,
      ingredientType: ingredientType
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  })

  return (
    <div
      className="card"
      style={{
        minWidth: '58px',
        opacity: opacity,
        textAlign: 'center'
      }}
      ref={dragRef}
      title={name}
    >
      <div className="card-body" style={{cursor: 'move'}}>
        <i className={`bi ${iconName}`}></i>
      </div>
    </div>
  )
}

export default IngredientButton
