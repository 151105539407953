import _ from 'lodash'

import useIngredient from '../../../../hooks/useIngredient'
import {PropertiesSidebarProps} from '../../../../libs/types'
import Sidebar from '../Sidebar'

import PropertyRow from './PropertyRow'

function PropertiesSidebar({
  selectedIngredientType,
  selectedIngredientId
}: PropertiesSidebarProps) {
  const [ingredient] = useIngredient(selectedIngredientType, selectedIngredientId)

  const sortedProperties = _.sortBy(Object.entries(ingredient.properties), [e => e[0]])

  return (
    <Sidebar
      header={`${ingredient.displayName} Properties`}
      hasPadding={false}
      width={300}
    >
      <table className="table table-sm">
        <tbody>
          {sortedProperties.map(
            ([key, property]) => {
              return <PropertyRow
                key={key}
                ingredientType={selectedIngredientType}
                ingredientId={selectedIngredientId}
                propertyKey={key}
                property={property}
              />
            }
          )}
        </tbody>
      </table>
    </Sidebar>
  )
}

export default PropertiesSidebar
