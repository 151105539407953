import {Tooltip} from 'bootstrap'
import {MouseEvent, useContext, useEffect} from 'react'

import {IngredientsContext} from '../../../../contexts/IngredientsProvider'
import useIngredient from '../../../../hooks/useIngredient'
import {IngredientState, RevertButtonProps} from '../../../../libs/types'

function RevertButton({ingredientType, ingredientId, propertyKey, property}: RevertButtonProps) {
  const ingredientsContext = useContext(IngredientsContext)!

  const [ingredient] = useIngredient(ingredientType, ingredientId)

  const handleClick = () => {
    const newIngredient: IngredientState = JSON.parse(JSON.stringify(ingredient))
    newIngredient.properties[propertyKey].value.value = newIngredient.properties[propertyKey].defaultValue.value
    newIngredient.properties[propertyKey].value.type = newIngredient.properties[propertyKey].defaultValue.type

    ingredientsContext.ingredientsDispatch(
      ingredientsContext.ingredientsActionCreators.update(
        ingredientType,
        ingredientId,
        newIngredient
      )
    )
  }

  useEffect(
    () => {
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tooltipTriggerEl =>
        new Tooltip(tooltipTriggerEl)
      )
    },
    []
  )

  return(
    <button
      className="btn btn-sm btn-outline-danger"
      type="button"
      id={`property-${propertyKey}`}
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      title={`Revert to "${property.defaultValue.value}"`}
      disabled={property.value.value === property.defaultValue.value &&
        property.value.type === property.defaultValue.type
      }
      onClick={(_: MouseEvent) => handleClick()}
    >
      <i className="bi bi-arrow-counterclockwise"></i>
    </button>
  )
}

export default RevertButton
