import {Id} from 'normalized-reducer'

import ComponentRenderer from '../../../renderers/ComponentRenderer'
import {IngredientTypes, IngredientsContextType, IIngredientRenderer} from '../../types'

import ButtonRenderer from './ButtonRenderer'
import GridRenderer from './containers/GridRenderer'
import HBoxRenderer from './containers/HBoxRenderer'
import VBoxRenderer from './containers/VBoxRenderer'
import ImageRenderer from './ImageRenderer'
import SpacerRenderer from './SpacerRenderer'
import TextRenderer from './TextRenderer'
import UseStateRenderer from './UseStateRenderer'

class IngredientRendererFactory {
  getRenderer(ingredientsContext: IngredientsContextType, ingredientType: IngredientTypes, ingredientId: Id): IIngredientRenderer {
    switch (ingredientType) {
      case 'button':
        return new ButtonRenderer(ingredientsContext, 'component', ingredientId)
      case 'grid':
        return new GridRenderer(ingredientsContext, 'component', ingredientId)
      case 'hbox':
        return new HBoxRenderer(ingredientsContext, 'component', ingredientId)
      case 'image':
        return new ImageRenderer(ingredientsContext, 'component', ingredientId)
      case 'spacer':
        return new SpacerRenderer(ingredientsContext, 'component', ingredientId)
      case 'text':
        return new TextRenderer(ingredientsContext, 'component', ingredientId)
      case 'vbox':
        return new VBoxRenderer(ingredientsContext, 'component', ingredientId)
      case 'use-state':
        return new UseStateRenderer(ingredientsContext, 'hook', ingredientId)
      case 'component':
        return new ComponentRenderer(ingredientsContext, 'component', ingredientId)
    }
  }
}

export default IngredientRendererFactory
