import useContainer from '../../../../hooks/useContainer'
import {ConcreteIngredientProps} from '../../../types'

function Grid({ingredientId}: ConcreteIngredientProps) {
  const [renderedChildren, dropRef] = useContainer('component', ingredientId)

  return (
    <div
      className="d-flex flex-wrap gap-3"
      style={{width: '100%'}}
      ref={dropRef}
    >
      {renderedChildren}
    </div>
  )
}

export default Grid
