const schema = {
  hook: {
    'parentId': {type: 'component', cardinality: 'one', reciprocal: 'hookIds'}
  },
  component: {
    'parentId': {type: 'component', cardinality: 'one', reciprocal: 'childrenIds'},
    'hookIds': {type: 'hook', cardinality: 'many', reciprocal: 'parentId'},
    'childrenIds': {type: 'component', cardinality: 'many', reciprocal: 'parentId'}
  }
}

export default schema
