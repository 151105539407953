// https://gist.github.com/johanquiroga/cbbfc0da2e9f11d2dbfd15acc4db6fc0

import {Action, Reducer, State} from 'normalized-reducer'
import {useReducer} from 'react'

import {undoState} from '../libs/types'

const useUndoReducer = <T extends State>(reducer: Reducer<T>, initialState: T) => {
  const undoState: undoState<T> = {
    past: [],
    present: initialState,
    future: []
  }

  const undoReducer = (state: undoState<T>, action: Action<T> | {type: string}) => {
    switch(action.type) {
      case useUndoReducer.types.undo: {
        const [newPresent, ...past] = state.past

        return {
          past: past,
          present: newPresent,
          future: [state.present, ...state.future]
        }
      }

      case useUndoReducer.types.redo: {
        const [newPresent, ...future] = state.future

        return {
          past: [state.present, ...state.past],
          present: newPresent,
          future: future
        }
      }

      default: {
        const newPresent = reducer(state.present, action as Action<T>)

        return {
          past: [state.present, ...state.past],
          present: newPresent,
          future: []
        }
      }
    }
  }

  return useReducer(undoReducer, undoState)
}

useUndoReducer.types = {
  undo: 'UNDO',
  redo: 'REDO'
}

export default useUndoReducer
