import {Id} from 'normalized-reducer'
import {useContext} from 'react'

import {SelectedIngredientContext} from '../contexts/SelectedIngredientProvider'
import {DragObjectType, setSelectedIngredient} from '../libs/types'

function useFocus(): [Id, setSelectedIngredient] {
  const selectedIngredientContext = useContext(SelectedIngredientContext)!

  function setSelectedIngredient(type: DragObjectType, id: Id) {
    selectedIngredientContext.setSelectedIngredientType(type)
    selectedIngredientContext.setSelectedIngredientId(id)
  }

  return [selectedIngredientContext.selectedIngredientId, setSelectedIngredient]
}

export default useFocus
