import Sidebar from '../Sidebar'

import IngredientButton from './IngredientButton'

function IngredientsSidebar() {
  return (
    <Sidebar header="Ingredients" width={240}>
      <h2 className="h6">Hooks</h2>

      <div className="d-flex flex-fill flex-wrap gap-3 mb-3">
        <IngredientButton
          name="useState"
          iconName="bi-code"
          type="hook"
          ingredientType="use-state"
        />
      </div>

      <h2 className="h6">Components</h2>

      <div className="d-flex flex-fill flex-wrap gap-3">
        <IngredientButton
          name="Grid"
          iconName="bi-grid-fill"
          type="component"
          ingredientType="grid"
        />

        <IngredientButton
          name="HBox"
          iconName="bi-distribute-horizontal"
          type="component"
          ingredientType="hbox"
        />

        <IngredientButton
          name="VBox"
          iconName="bi-distribute-vertical"
          type="component"
          ingredientType="vbox"
        />

        <IngredientButton
          name="Spacer"
          iconName="bi-arrows-fullscreen"
          type="component"
          ingredientType="spacer"
        />

        <IngredientButton
          name="Button"
          iconName="bi-app"
          type="component"
          ingredientType="button"
        />

        <IngredientButton
          name="Text Box"
          iconName="bi-input-cursor-text"
          type="component"
          ingredientType="text"
        />

        <IngredientButton
          name="Image"
          iconName="bi-image"
          type="component"
          ingredientType="image"
        />
      </div>
    </Sidebar>
  )
}

export default IngredientsSidebar
