import {useContext} from 'react'

import {CanvasContext} from '../../contexts/CanvasProvider'
import useContainer from '../../hooks/useContainer'
import {IngredientsContainerProps} from '../../libs/types'

function IngredientsContainer({
  type,
  componentId,
  title,
  direction,
  style,
  dropRef
}: IngredientsContainerProps) {
  const canvasContext = useContext(CanvasContext)!

  const [renderedChildren] = useContainer(type, componentId)

  if (canvasContext.mode === 'design') {
    return (
      <div
        className="card text-center flex-fill"
        style={style}
      >
        <div className="card-header">
          {title}
        </div>

        <div className={`card-body d-flex overflow-auto flex-${direction} gap-3 p-1`}ref={dropRef}>
          {renderedChildren}
        </div>
      </div>
    )
  } else {
    return (
      <>
        {renderedChildren}
      </>
    )
  }
}

export default IngredientsContainer
