import {Mode, PropertyValue, simulatedState} from './types'

class PropertyValueHelper {
  #simulatedState: simulatedState
  #value: PropertyValue

  constructor(simulatedState: simulatedState, value: PropertyValue) {
    this.#simulatedState = simulatedState
    this.#value = value
  }

  getValue() {
    if (this.#value.type ==='literal') {
      return this.#value.value.toString()
    } else {
      return this.#simulatedState[this.#value.value].toString()
    }
  }

  displayValue() {
    if (this.#value.type ==='literal') {
      return this.#value.value.toString()
    } else {
      return `{${this.#value.value}}`
    }
  }

  renderValue(mode: Mode) {
    return mode === 'design' ?
    this.displayValue() :
    this.getValue()
  }
}

export default PropertyValueHelper
