import IngredientRenderer from '../../../renderers/IngredientRenderer'
import {IIngredientRenderer} from '../../types'
import Image from '../components/Image'

class ImageRenderer extends IngredientRenderer implements IIngredientRenderer {
  render() {
    return <Image ingredientId={this.ingredientId} />
  }

  generateCode() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    const srcString = this.generatePropCode(
      'src', ingredient?.properties.source.value.value,
      ingredient?.properties.source.value.type
      )

    return `<img ${srcString} alt="" />`
  }
}

export default ImageRenderer
