import clsx from 'clsx'
import {MouseEvent, useContext} from 'react'

import {IngredientsContext} from '../../../../contexts/IngredientsProvider'
import useIngredient from '../../../../hooks/useIngredient'
import {IngredientState, RevertButtonProps} from '../../../../libs/types'

function ExpressionButton({ingredientType, ingredientId, propertyKey, property}: RevertButtonProps) {
  const ingredientsContext = useContext(IngredientsContext)!

  const [ingredient] = useIngredient(ingredientType, ingredientId)

  const getStates = () => {
    const hooks = ingredientsContext.ingredientsSelectors.getEntities<IngredientState>(
      ingredientsContext.ingredientsState,
      {type: 'hook'}
    )

    return hooks
  }

  const handleClick = (hookName: string | number) => {
    const newIngredient: IngredientState = JSON.parse(JSON.stringify(ingredient))
    newIngredient.properties[propertyKey].value.type = 'expression'
    newIngredient.properties[propertyKey].value.value = hookName

    ingredientsContext.ingredientsDispatch(
      ingredientsContext.ingredientsActionCreators.update(
        ingredientType,
        ingredientId,
        newIngredient
      )
    )
  }

  return(
    <>
      <button
        className={clsx(
          'btn btn-sm btn-outline-secondary dropdown-toggle'
          // property.value.type === 'expression' ? 'btn-outline-primary' : 'btn-outline-secondary'
        )}
        type="button"
        id={`property-${propertyKey}`}
        data-bs-toggle="dropdown"
        title={`Revert to "${property.defaultValue}"`}
        aria-expanded="false"
      >
        <i className="bi bi-code"></i>
      </button>
      <ul className="dropdown-menu">
        {Object.entries(getStates()).map(hook => (
          <li key={hook[0]}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="dropdown-item" href="#" onClick={
              (_: MouseEvent) => handleClick(hook[1].properties.name.value.value)
            }>
              {hook[1].properties.name.value.value}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export default ExpressionButton
