import clsx from 'clsx'
import {useContext} from 'react'

import {CanvasContext} from '../../../contexts/CanvasProvider'
import {SimulatedStateContext} from '../../../contexts/SimulatedStateProvider'
import useIngredient from '../../../hooks/useIngredient'
import PropertyValueHelper from '../../../libs/PropertyValueHelper'
import {ConcreteIngredientProps} from '../../types'

function Button({ingredientId}: ConcreteIngredientProps) {
  const simulatedStateContext = useContext(SimulatedStateContext)!

  const [ingredient] = useIngredient('component', ingredientId)
  const canvasContext = useContext(CanvasContext)!

  const propertyValueHelper = new PropertyValueHelper(
    simulatedStateContext.simulatedState,
    ingredient.properties.text.value
  )

  return (
    <button
      type="button"
      className={clsx(
        `btn btn-${ingredient.properties.style.value.value}`,
        ingredient.properties.size.value.value !== 'md' && `btn-${ingredient.properties.size.value.value}`
      )}
      style={{
        pointerEvents: 'none',
        width: canvasContext.mode === 'design' ? '100%' : ''
      }}
      disabled={ingredient.properties.enabled.value.value === 'false'}
      tabIndex={canvasContext.mode === 'design' ? -1 : undefined}
    >
      {propertyValueHelper.renderValue(canvasContext.mode)}
    </button>
  )
}

export default Button
