import clsx from 'clsx'

import {SidebarProps} from '../../../libs/types'

function Sidebar({header, hasPadding, width, children}: SidebarProps) {
  return (
    <div
      className="card d-inline-block flex-shrink-0 sidebar"
      style={{width}}
    >
      <div className="card-header text-center">
        {header}
      </div>

      <div className={clsx('card-body', !hasPadding && 'p-0')}>
        {children}
      </div>
    </div>
  )
}

Sidebar.defaultProps = {
  hasPadding: true
}

export default Sidebar
