import clsx from 'clsx'
import {DropTargetMonitor, useDrop} from 'react-dnd'

import {IngredientDragType, DropzoneProps} from '../../libs/types'

function Dropzone({ingredientId, index, acceptableType, show, handleDrop}: DropzoneProps) {
  const [{isOver, draggedIndex, draggedParentId}, drop] = useDrop({
    accept: acceptableType,
    drop: (ingredientDragObject: IngredientDragType) => {
      handleDrop(ingredientDragObject, index)
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      draggedIndex: monitor.getItem()?.index,
      draggedParentId: monitor.getItem()?.parentId
    })
  })

  const isDisabled = draggedParentId === ingredientId &&
    !(draggedIndex === undefined || index < draggedIndex || index > draggedIndex + 1)

  return (
    <>
      {show && (
        <div
          className={clsx(
            'card d-flex flex-grow-1 flex-shrink-0',
            isDisabled && 'border-secondary text-secondary dropzone-disabled',
            isOver && !isDisabled && 'bg-info text-white',
            !isOver && !isDisabled && 'border-info text-info dropzone'
          )}
          ref={drop}
          data-testid={`Dropzone-${acceptableType}-${ingredientId}-${index}`}
        >
          <div className="align-items-center card-body d-flex justify-content-center p-0">
            <i className="bi bi-plus" style={{fontSize: '3rem'}}></i>
          </div>
        </div>
      )}
    </>
  )
}

export default Dropzone
