import {Id} from 'normalized-reducer'

import IngredientRendererFactory from '../libs/bootstrap/renderers/IngredientRendererFactory'
import {IIngredientRenderer} from '../libs/types'

import IngredientRenderer from './IngredientRenderer'

abstract class ContainerRenderer extends IngredientRenderer implements IIngredientRenderer {
  protected indentation: number = 1

  protected indent() {
    return '  '.repeat(this.indentation)
  }

  protected generateChildren() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    if (ingredient?.childrenIds === undefined) {
      return ''
    }

    return ingredient.childrenIds.map((childId: Id) => {
      const child = this.getIngredient('component', childId)

      return new IngredientRendererFactory().getRenderer(
        this.ingredientsContext,
        child?.type!,
        childId
      ).generateCode().split('\n').map((line: string) => `${this.indent()}${line}`).join('\n')
    }).join('\n\n')
  }
}

export default ContainerRenderer
