import {Id} from 'normalized-reducer'
import {createContext, Dispatch} from 'react'

import {DragObjectType, SelectedIngredientProviderProps} from '../libs/types'

export const SelectedIngredientContext = createContext<{
  selectedIngredientType: Id
  setSelectedIngredientType: Dispatch<React.SetStateAction<DragObjectType>>
  selectedIngredientId: Id
  setSelectedIngredientId: Dispatch<React.SetStateAction<Id>>
 } | null>(null)

function SelectedIngredientProvider({
  selectedIngredientType,
  setSelectedIngredientType,
  selectedIngredientId,
  setSelectedIngredientId,
  children
}: SelectedIngredientProviderProps) {
  return (
    <SelectedIngredientContext.Provider value={{
      selectedIngredientType,
      setSelectedIngredientType,
      selectedIngredientId,
      setSelectedIngredientId
    }}>
      {children}
    </SelectedIngredientContext.Provider>
  )
}

export default SelectedIngredientProvider
