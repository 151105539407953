import {MouseEvent} from 'react'

import {NavbarProps} from '../../libs/types'

function Navbar({canUndo, canRedo, undo, redo}: NavbarProps) {
  const handleUndo = () => {
    undo()
  }

  const handleRedo = () => {
    redo()
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
      <div className="container-fluid justify-content-start">
        <span className="navbar-brand">Visual React</span>

        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            disabled={canUndo === false}
            onClick={(_: MouseEvent<HTMLButtonElement>) => handleUndo()}
          >
            Undo
          </button>

          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            disabled={canRedo === false}
            onClick={(_: MouseEvent<HTMLButtonElement>) => handleRedo()}
          >
            Redo
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
