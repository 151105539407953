import {Id} from 'normalized-reducer'
import {useReducer, useState} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

import IngredientsProvider from '../contexts/IngredientsProvider'
import SelectedIngredientProvider from '../contexts/SelectedIngredientProvider'
import SimulatedStateProvider from '../contexts/SimulatedStateProvider'
import useIngredients from '../hooks/useIngredients'
import {DragObjectType} from '../libs/types'
import simulatedStateReducer from '../reducers/simulatedStateReducer'

import Canvas from './canvas/Canvas'
import Navbar from './layout/Navbar'
import IngredientsSidebar from './layout/sidebars/IngredientsSidebar/IngredientsSidebar'
import PropertiesSidebar from './layout/sidebars/PropertiesSidebar/PropertiesSidebar'

import '../assets/stylesheets/App.scss'

function App() {
  const [
    ingredientsState,
    ingredientsDispatch,
    ingredientsActionCreators,
    ingredientsSelectors,
    canUndo,
    canRedo,
    undo,
    redo,
    rootComponentId
  ] = useIngredients()

  const [
    selectedIngredientType,
    setSelectedIngredientType
  ] = useState<DragObjectType>('component')

  const [
    selectedIngredientId,
    setSelectedIngredientId
  ] = useState<Id>(rootComponentId)

  const [simulatedState, simulatedStateDispatch] = useReducer(simulatedStateReducer, {})

  return (
    <div className="d-flex flex-column flex-fill gap-1 h-100 m-0 overflow-hidden">
      <Navbar canUndo={canUndo} canRedo={canRedo} undo={undo} redo={redo} />

      <DndProvider backend={HTML5Backend}>
        <div className="d-flex flex-fill gap-1 app-container">
          <IngredientsSidebar />

          <IngredientsProvider
            ingredientsState={ingredientsState.present}
            ingredientsDispatch={ingredientsDispatch}
            ingredientsActionCreators={ingredientsActionCreators}
            ingredientsSelectors={ingredientsSelectors}
          >
            <SimulatedStateProvider
              simulatedState={simulatedState}
              simulatedStateDispatch={simulatedStateDispatch}
            >
              <SelectedIngredientProvider
                selectedIngredientType={selectedIngredientType}
                setSelectedIngredientType={setSelectedIngredientType}
                selectedIngredientId={selectedIngredientId}
                setSelectedIngredientId={setSelectedIngredientId}
              >
                <Canvas componentId={rootComponentId} />
              </SelectedIngredientProvider>

              <PropertiesSidebar
                selectedIngredientType={selectedIngredientType}
                selectedIngredientId={selectedIngredientId}
              />
            </SimulatedStateProvider>
          </IngredientsProvider>
        </div>
      </DndProvider>
    </div>
  )
}

export default App
