import {createContext} from 'react'

import {IngredientsContextType, IngredientsContextProps} from '../libs/types'

export const IngredientsContext = createContext<IngredientsContextType | null>(null)

function IngredientsProvider({
  ingredientsState,
  ingredientsDispatch,
  ingredientsActionCreators,
  ingredientsSelectors,
  children
}: IngredientsContextProps) {
  return (
    <IngredientsContext.Provider value={{
      ingredientsState,
      ingredientsDispatch,
      ingredientsActionCreators,
      ingredientsSelectors
    }}>
      {children}
    </IngredientsContext.Provider>
  )
}

export default IngredientsProvider
