import {Id} from 'normalized-reducer'
import {useContext} from 'react'

import {IngredientsContext} from '../contexts/IngredientsProvider'
import {IngredientState, DragObjectType} from '../libs/types'

function useIngredient(
  type: DragObjectType,
  id: Id
): [IngredientState] {
  const ingredientsContext = useContext(IngredientsContext)!

  return [ingredientsContext.ingredientsSelectors.getEntity<IngredientState>(
    ingredientsContext.ingredientsState, {type, id}
  )!]
}

export default useIngredient
