import makeNormalizedSlice, {Id} from 'normalized-reducer'
import {useCallback} from 'react'

import ingredientInitialStates from '../libs/ingredientInitialStates'
import schema from '../libs/schema'
import {actionCreators, dispatch, IngredientsState, redo, selectors, undo, undoState} from '../libs/types'

import useUndoReducer from './useUndoReducer'

function useIngredients(): [
  undoState<IngredientsState>,
  dispatch,
  actionCreators,
  selectors,
  boolean,
  boolean,
  undo,
  redo,
  Id
] {
  const {
    reducer,
    actionCreators,
    selectors
  } = makeNormalizedSlice<IngredientsState>(schema)

  const componentId = 'app'

  const componentInitialState = ingredientInitialStates['component']
  componentInitialState.properties.name.value.value = 'App'
  componentInitialState.properties.name.defaultValue.value = 'App'

  const [state, dispatch] = useUndoReducer<IngredientsState>(reducer, {
    entities: {
      component: {
        [componentId]: componentInitialState
      },
      hook: {}
    },
    ids: {
      component: [componentId],
      hook: []
    }
  })

  const canUndo = state.past.length > 0

  const canRedo = state.future.length > 0

  const undo = useCallback(() => {
    dispatch({type: useUndoReducer.types.undo})
  }, [dispatch])

  const redo = useCallback(() => {
    dispatch({type: useUndoReducer.types.redo})
  }, [dispatch])

  return [
    state,
    dispatch,
    actionCreators,
    selectors,
    canUndo,
    canRedo,
    undo,
    redo,
    componentId
  ]
}

export default useIngredients
