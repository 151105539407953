import useContainer from '../../../../hooks/useContainer'
import {ConcreteIngredientProps} from '../../../types'

function HBox({ingredientId}: ConcreteIngredientProps) {
  const [renderedChildren, dropRef] = useContainer('component', ingredientId)

  return (
    <div
      className="d-flex gap-3"
      style={{overflowX: 'auto', width: '100%'}}
      ref={dropRef}
    >
      {renderedChildren}
    </div>
  )
}

export default HBox
