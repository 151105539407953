import {createContext} from 'react'

import {SimulatedStateProviderProps, simulatedState, SimulatedStateAction} from '../libs/types'

export const SimulatedStateContext = createContext<{
  simulatedState: simulatedState
  simulatedStateDispatch: React.Dispatch<SimulatedStateAction>
} | null>(null)

function SimulatedStateProvider({
  simulatedState,
  simulatedStateDispatch,
  children
}: SimulatedStateProviderProps) {
  return (
    <SimulatedStateContext.Provider value={{
      simulatedState,
      simulatedStateDispatch
    }}>
      {children}
    </SimulatedStateContext.Provider>
  )
}

export default SimulatedStateProvider
