import clsx from 'clsx'
import {MouseEvent, useContext} from 'react'
import {useDrag} from 'react-dnd'

import {CanvasContext} from '../../contexts/CanvasProvider'
import {IngredientsContext} from '../../contexts/IngredientsProvider'
import useFocus from '../../hooks/useFocus'
import useIngredient from '../../hooks/useIngredient'
import IngredientRendererFactory from '../../libs/bootstrap/renderers/IngredientRendererFactory'
import {IngredientProps, IngredientState} from '../../libs/types'

function Ingredient({type, ingredientId, index, className}: IngredientProps) {
  const canvasContext = useContext(CanvasContext)!
  const ingredientsContext = useContext(IngredientsContext)!

  const [ingredient] = useIngredient(type, ingredientId)
  const [selectedIngredientId, setSelectedIngredient] = useFocus()

  const isComponent = ingredient.parentId === undefined

  const ingredientRenderer = new IngredientRendererFactory().getRenderer(
    ingredientsContext,
    ingredient.type,
    ingredientId
  )
  const IngredientToRender = ingredientRenderer.render()

  const [{opacity}, dragRef, previewRef] = useDrag({
    item: {
      type: type,
      index: index,
      ingredientType: ingredient.type,
      id: ingredientId,
      parentId: ingredient.parentId
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.5 : 1
    })
  })

  const handleFocus = (e: MouseEvent<HTMLDivElement>) => {
    setSelectedIngredient(type, ingredientId)
    e.stopPropagation()
  }

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    var shouldDelete = true

    if (ingredient.type === 'use-state') {
      const components = ingredientsContext.ingredientsSelectors.getEntities<IngredientState>(
        ingredientsContext.ingredientsState,
        {type: 'component'}
      )

      for (const component of Object.entries(components)) {
        const properties = Object.values(component[1].properties)

        for (const property of properties) {
          if (property.value.type === 'expression' && property.value.value === ingredient.properties.name.value.value) {
            shouldDelete = false
            break
          }
        }

        if (!shouldDelete) {
          break
        }
      }
    }

    if (shouldDelete) {
      // if (selectedIngredientContext.selectedIngredientId === ingredientId) {
        setSelectedIngredient('component', 'app')
      // }

      ingredientsContext.ingredientsDispatch(
        ingredientsContext.ingredientsActionCreators.delete(
          type,
          ingredientId,
          {childrenIds: {}}
        )
      )
    } else {
      // TODO: Figure out a better way.
      alert('This state cannot be deleted because there is a component property that is bound to it.')
    }

    e.stopPropagation()
  }

  const deleteButton = (
    <button
      type="button"
      className="btn btn-close btn-sm float-start"
      onClick={handleDelete}
    />
  )

  const ingredientNameString = ingredient.properties.hasOwnProperty('name') ?
    ` (${ingredient.properties.name.value.value})` :
    ''

  return (
    canvasContext.mode === 'design' ? (
      <div
        className={clsx(
          className,
          'card flex-shrink-0',
          ...ingredientRenderer.classes,
          selectedIngredientId === ingredientId ? 'border-primary' : 'border-secondary',
          isComponent && 'flex-fill'
        )}
        style={{opacity}}
        onClick={handleFocus}
        ref={isComponent ? undefined : previewRef}
        data-testid={`Ingredient-${ingredientId}`}
      >
        <div
          className="card-header p-1"
          style={{cursor: isComponent ? undefined : 'move'}}
          ref={isComponent ? undefined : dragRef}
        >
          {!isComponent && deleteButton}

          {`${ingredient.displayName}${ingredientNameString}`}
        </div>

        <div className="card-body d-flex flex-column gap-3 p-1">
          {IngredientToRender}
        </div>
      </div>
    ) : (
      <>
        {IngredientToRender}
      </>
    )
  )
}

Ingredient.defaultProps = {
  draggable: true,
  deletable: true
}

export default Ingredient
