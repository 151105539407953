import {useContext} from 'react'

import {CanvasContext} from '../../../contexts/CanvasProvider'
import {SimulatedStateContext} from '../../../contexts/SimulatedStateProvider'
import useIngredient from '../../../hooks/useIngredient'
import PropertyValueHelper from '../../../libs/PropertyValueHelper'
import {ConcreteIngredientProps} from '../../types'

function Text({ingredientId}: ConcreteIngredientProps) {
  const simulatedStateContext = useContext(SimulatedStateContext)!

  const [ingredient] = useIngredient('component', ingredientId)
  const canvasContext = useContext(CanvasContext)!

  const propertyValueHelper = new PropertyValueHelper(
    simulatedStateContext.simulatedState,
    ingredient.properties.text.value
  )

  return (
    <input
      className="form-control"
      style={{pointerEvents: canvasContext.mode === 'design' ? 'none' : undefined}}
      disabled={ingredient.properties.enabled.value.value === 'false'}
      tabIndex={canvasContext.mode === 'design' ? -1 : undefined}
      value={propertyValueHelper.renderValue(canvasContext.mode)}
    />
  )
}

export default Text
