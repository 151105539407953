import clsx from 'clsx'

import useContainer from '../../../../hooks/useContainer'
import useIngredient from '../../../../hooks/useIngredient'
import {ConcreteIngredientProps} from '../../../types'

function VBox({ingredientId}: ConcreteIngredientProps) {
  const [renderedChildren, dropRef] = useContainer('component', ingredientId)
  const [ingredient] = useIngredient('component', ingredientId)

  return (
    <div
      className={clsx(
        'd-flex flex-column gap-3',
        ingredient.parentId === undefined && 'flex-fill'
      )}
      style={{width: '100%'}}
      ref={dropRef}
    >
      {renderedChildren}
    </div>
  )
}

export default VBox
