import HBox from '../../../../libs/bootstrap/components/containers/HBox'
import ContainerRenderer from '../../../../renderers/ContainerRenderer'
import {IIngredientRenderer} from '../../../types'

class HBoxRenderer extends ContainerRenderer implements IIngredientRenderer {
  render() {
    return <HBox ingredientId={this.ingredientId} />
  }

  generateCode() {
    return `<div className="d-flex gap-3" style={{width: '100%'}}>
${this.generateChildren()}
</div>`
  }
}

export default HBoxRenderer
