import clsx from 'clsx'
import {ChangeEvent, useContext} from 'react'

import {IngredientsContext} from '../../../../contexts/IngredientsProvider'
import {SimulatedStateContext} from '../../../../contexts/SimulatedStateProvider'
import useIngredient from '../../../../hooks/useIngredient'
import {SimulatedStateActionType} from '../../../../libs/actionTypes'
import PropertyValueHelper from '../../../../libs/PropertyValueHelper'
import {IngredientState, Option, PropertyRowProps} from '../../../../libs/types'

import ExpressionButton from './ExpressionButton'
import RevertButton from './RevertButton'

function PropertyRow({ingredientType, ingredientId, propertyKey, property}: PropertyRowProps) {
  const ingredientsContext = useContext(IngredientsContext)!
  const simulatedStateContext = useContext(SimulatedStateContext)!

  const [ingredient] = useIngredient(ingredientType, ingredientId)

  const getType = () => {
    if ((propertyKey === 'defaultValue') && 'type' in ingredient.properties) {
      return ingredient.properties.type.value.value
    } else {
      return property.type
    }
  }

  const handleChange = (value: string) => {
    const newIngredient: IngredientState = JSON.parse(JSON.stringify(ingredient))

    newIngredient.properties[propertyKey].value.type = 'literal'
    newIngredient.properties[propertyKey].value.value = value

    ingredientsContext.ingredientsDispatch(
      ingredientsContext.ingredientsActionCreators.update(
        ingredientType,
        ingredientId,
        newIngredient
      )
    )

    if (ingredient.type === 'use-state' &&
      (propertyKey === 'value' || propertyKey === 'defaultValue')
    ) {
      simulatedStateContext.simulatedStateDispatch({
        type: SimulatedStateActionType.setState,
        payload: {
          key: ingredient.properties.name.value.value as string,
          value: value
        }
      })
    }

    // TODO
    // if (propertyKey === 'type') {
    // }
  }

  const renderSelect = () => (
    <select
      className="form-select form-select-sm"
      aria-label={property.displayName}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e.target.value)}
      value={property.value.value}
    >
      {property.options !== undefined ? (
        property.options.map((option: Option) => {
          return (
            <option
              value={option.value}
              key={option.value}
            >
              {option.displayName}
            </option>
          )
        })
      ) : (
        <>
          <option value='true'>True</option>

          <option value='false'>False</option>
        </>
      )}
    </select>
  )

  const renderInput = () => (
    <input
      type={getType() === 'string' ? 'text' : 'number'}
      className={clsx(
        'form-control form-control-sm',
        property.value.type === 'expression' && 'font-monospace'
      )}
      style={{fontSize: property.value.type === 'expression' ? '12px' : undefined}}
      placeholder={property.displayName}
      aria-label={property.displayName}
      aria-describedby={`property-${propertyKey}`}
      value={new PropertyValueHelper(
        simulatedStateContext.simulatedState,
        property.value
      ).displayValue()}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
    />
  )

  return (
    <tr>
      <td className="align-middle text-nowrap"><small>{property.displayName}</small></td>

      <td>
        <div className="input-group">
          {((property.options !== undefined || getType() === 'boolean') ?
            renderSelect() :
            renderInput()
          )}

          {property.canBeAnExpression === true && (
            <ExpressionButton
              ingredientType={ingredientType}
              ingredientId={ingredientId}
              propertyKey={propertyKey}
              property={property}
            />
          )}

          <RevertButton
            ingredientType={ingredientType}
            ingredientId={ingredientId}
            propertyKey={propertyKey}
            property={property}
          />
        </div>
      </td>
    </tr>
  )
}

export default PropertyRow
