import {useContext} from 'react'

import {SimulatedStateContext} from '../../../contexts/SimulatedStateProvider'
import useIngredient from '../../../hooks/useIngredient'
import PropertyValueHelper from '../../../libs/PropertyValueHelper'
import {ConcreteIngredientProps} from '../../types'

function Image({ingredientId}: ConcreteIngredientProps) {
  const simulatedStateContext = useContext(SimulatedStateContext)!

  const [ingredient] = useIngredient('component', ingredientId)

  const propertyValueHelper = new PropertyValueHelper(
    simulatedStateContext.simulatedState,
    ingredient.properties.source.value
  )

  return (
    <img
      src={propertyValueHelper.getValue()}
      style={{pointerEvents: 'none'}}
      alt=""
    />
  )
}

export default Image
