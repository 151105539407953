import IngredientRenderer from '../../../renderers/IngredientRenderer'
import {IIngredientRenderer} from '../../types'
import Text from '../components/Text'

class TextRenderer extends IngredientRenderer implements IIngredientRenderer {
  render() {
    return <Text ingredientId={this.ingredientId} />
  }

  // TODO: Make private.
  negateStringBooleanValue(value: string | number | undefined) {
    if (value === 'true') {
      return 'false'
    } else {
      return 'true'
    }
  }

  generateCode() {
    const ingredient = this.getIngredient(this.ingredientType, this.ingredientId)

    const textString = this.generatePropCode(
      'text', ingredient?.properties.text.value.value,
      ingredient?.properties.text.value.type
      )

      const disabledString = this.generatePropCode(
        'disabled', this.negateStringBooleanValue(ingredient?.properties.enabled.value.value),
        ingredient?.properties.enabled.value.type,
        this.negateStringBooleanValue(ingredient?.properties.enabled.defaultValue.value),
        ingredient?.properties.enabled.defaultValue.type,
        true
        )

    return `<input className="form-control" ${textString} ${disabledString} />`
  }
}

export default TextRenderer
